import React from 'react'
import { MdContentCopy } from 'react-icons/md'
import { Button } from '~/components'
import { Copy } from './style'

export default function PaymentButton({ item }) {
    if (!item.paymentLink) {
        return null
    }

    return (
        <>
            <Button isRequesting={item.isGenerating || false} className="payWithBoleto" onClick={() => window.open(item.paymentLink)}>
                Boleto bancário
            </Button>
            {item.linhaDigitavel && (
                <Copy text={item.linhaDigitavel} onCopy={() => alert('Linha digitável copiada com sucesso!')}>
                    <span>
                        <MdContentCopy /> Copiar linha digitável do boleto
                    </span>
                </Copy>
            )}
        </>
    )
}
