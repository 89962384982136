import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { Page, Table, Title, Alert } from '~/components'
import FinanceService from '~/services/FinanceService'
import { withAuth } from '~/utils/withAuth'
import { utils } from '~/utils/Utils'
import credit_card_brands from '~/assets/images/credit_card_brands.png'
import '~/assets/styles/css/finance.css'
import PaymentButton from './payment_button'

class Finance extends React.Component {
    state = {
        finances: [],
        isRequesting: true,
    }

    get financeService() {
        if (!this._financeService) {
            this._financeService = new FinanceService(this.props)
        }
        return this._financeService
    }

    isNotOlder(finance) {
        const receitaIds = this.state.finances.filter(e => !e.isPaid).map(e => e.idReceita)
        const index = receitaIds.indexOf(finance.idReceita)
        if (index < receitaIds.length - 1) {
            // alert(this.props.getSettings('finance_need_pay_older_finance', 'É necessário quitar as receitas mais antigas'))
            alert('É necessário quitar as receitas mais antigas')
            return true
        }

        return false
    }

    setFinanceGenerating = (finance, json) => this.setState({ finances: this.state.finances.map(e => (e.idReceita === finance.idReceita ? { ...finance, ...json } : e)) })

    createInvoice = async finance => {
        if (this.isNotOlder(finance)) {
            return
        }
        const { filial } = this.props.user

        try {
            this.setFinanceGenerating(finance, { cardMessage: 'Gerando...' })
            const { endpoint } = await this.financeService.createInvoice(filial, finance.idReceita)
            this.setFinanceGenerating(finance, { cardMessage: 'Cobrança gerada. Redirecionado...' })
            setTimeout(() => this.setFinanceGenerating(finance, { cardMessage: null }), 3000)
            window.open(endpoint)
        } catch (error) {
            console.log('error', error)
            this.setFinanceGenerating(finance, { cardMessage: null })
            this.setState({ error: 'Não foi possível gerar a cobrança' })
        }
    }

    renderType = item => (
        <>
            <strong>{item.service}</strong>
            <div>{item.subservice}</div>
        </>
    )

    renderCharge = item => (
        <>
            <strong>{item.service}</strong>
            <div>{item.subservice}</div>
            <div>Referência: {item.dtReference}</div>
            <div>Valor devido: {this.renderValue(item)}</div>
        </>
    )

    renderValue = item => utils.formatPrice(item.valueWithFare)
    renderPaidValue = item => utils.formatPrice(item.valueWithFare)

    renderPayment = item => {
        if (item.isPaid) {
            return (
                <>
                    <strong>Pago</strong>
                    {!!item.dtPagto && <div>Valor pago: {this.renderValue(item)}</div>}
                    {!!item.dtPagto && <div>Pagto: {item.dtPagto}</div>}
                </>
            )
        }
        return (
            <div className={this.props.isMobile ? 'areaPaymentMobile' : 'areaPayment'}>
                {!!item.cardMessage && (
                    <>
                        <CircularProgress size={16} /> <strong>{item.cardMessage}</strong>
                    </>
                )}
                {!item.cardMessage && (
                    <>
                        <div>
                            <strong>Em aberto</strong>
                        </div>
                      
                        <PaymentButton item={item} />
                      
                        {item.canPagSeguro === 1 && (
                            <button type="button" className="payWithCard" onClick={() => this.createInvoice(item)}>
                                <img src={credit_card_brands} alt="Pagamento com cartão de crédito" />
                            </button>
                        )}
                    </>
                )}
            </div>
        )
    }

    renderMobile = (isRequesting, finances) => {
        if (isRequesting) {
            return (
                <Alert warning requesting>
                    Obtendo finanças...
                </Alert>
            )
        }

        return (
            <>
                <br />
                <Grid container className="oddRow">
                    <Grid item xs={12}>
                        Finanças
                    </Grid>
                </Grid>
                {finances.map((e, index) => (
                    <Grid key={index} container className={index % 2 === 0 ? 'evenRow' : 'oddRow'}>
                        <Grid item xs={12}>
                            <strong>
                                {e.service} - {e.subservice}
                            </strong>
                            <div>Referência: {e.dtReference}</div>
                            <div>Valor devido: {this.renderValue(e)}</div>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center', paddingTop: 10 }}>
                            {this.renderPayment(e)}
                        </Grid>
                    </Grid>
                ))}
            </>
        )
    }

    headers = () => [
        { title: 'Tipo', render: this.renderType },
        { title: 'Referência', field: 'dtReference' },
        // { title: 'Valor Devido', render: this.renderValue },
        { title: 'Valor', render: this.renderPaidValue },
        { title: 'Vencimento', field: 'dtVencto' },
        { title: 'Data de Pagamento', field: 'dtPagto' },
        { title: 'Imprimir', className: 'command', render: this.renderPayment },
    ]

    componentDidMount = async () => {
        const filial = this.props.filials.find(e => e.code === this.props.user.codeFilial)
        this.setState({ isRequesting: true })
        const json = { isRequesting: false }
        try {
            const finances = await this.financeService.finance(filial)
            json.finances = finances.filter(x => x.service?.toLowerCase()?.includes('mensalidade'))
        } catch (error) {
            console.log('error', error)
        } finally {
            this.setState(json)
        }
    }

    render = () => {
        const { isMobile } = this.props
        const { finances, isRequesting, error } = this.state
        return (
            <div className="finance">
                <Page card title="Financeiro" error={error} onDismissError={() => this.setState({ error: null })}>
                    <Title success underline>
                        Financeiro
                    </Title>
                    {!isMobile && (
                        <Table
                            requesting={isRequesting}
                            requestingMessage="Obtendo finanças..."
                            headers={this.headers()}
                            rows={finances}
                            rowClassName={({ isPaid }) => (isPaid ? 'paid' : '')}
                            // widths={[
                            //     0,0,0,0,0,0,100
                            // ]}
                        />
                    )}
                    {isMobile && this.renderMobile(isRequesting, finances)}
                </Page>
            </div>
        )
    }
}

export default withAuth(Finance)
